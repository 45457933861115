<template>
  <b-modal
    id="realized_procedures_form_modal"
    hide-header
    hide-footer
    centered size="xl"
    @hidden="onHidden"    
  >
      <div class="modal-header">
          <div class="modal-title">
            <span v-if="guide != 'hospitalization_summary'">
                Adicionar dados da execução do procedimento
            </span>

            <span v-else>
                Adicionar dados dos procedimentos e exames realizados
            </span>

          </div>
          <span class="icon-box"><Close class="icon stroke" @click="$bvModal.hide('realized_procedures_form_modal')" /></span>
      </div>

      <div class="around-content">
          <b-row>
              <b-col cols="12">
                  <p v-if="guide != 'hospitalization_summary'" class="form-title mb-3">
                    Dados da execução do procedimento
                  </p>

                  <p v-else class="form-title mb-3">
                    Dados dos procedimentos e Exames Realizados
                  </p>
              </b-col>

              <b-col cols="2">
                <b-form-group>
                  <label for="execution_date" class="title-styles">
                    Data
                  </label>
                  <date-picker
                    placeholder="Selecionar"
                    v-model="tissGuideItem.execution_date"
                    format="DD/MM/YYYY"
                    :clearable="false"
                    class="full"
                  />
                  <b-form-invalid-feedback :state="validProp('execution_date')">
                    Campo obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col cols="2">
                <b-form-group>
                  <label for="start_time" class="title-styles">
                    Hora inicial
                  </label>
                  <b-input
                    type="time"
                    placeholder="Descrever"
                    autocomplete="off"
                    v-model="tissGuideItem.start_time"
                    :state="validProp('start_time')"
                  />
                  <b-form-invalid-feedback :state="validProp('start_time')">
                    Campo obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col cols="2">
                <b-form-group>
                  <label for="end_time" class="title-styles">
                    Hora final
                  </label>
                  <b-input
                    type="time"
                    placeholder="Descrever"
                    autocomplete="off"
                    v-model="tissGuideItem.end_time"
                    :state="validProp('end_time')"
                    />
                    <b-form-invalid-feedback :state="validProp('end_time')">
                      Campo obrigatório
                    </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group>
                  <label for="procedure_id" class="title-styles">
                      Cód. do procedimento
                  </label>
                  <ItemHealthPlanSimpleSelector 
                      :clinicHealthPlanId="invoicingTissSetting.clinic_health_plan_id"
                      :planId="null"
                      :allowedTypes="['PROCEDURE','APPOINTMENT', 'EXAM', 'RETURN']"
                      v-model="tissGuideItem.item"
                      @select="setTissGuideItem"
                  />
                  <!-- <ProcedureFieldModule
                    :tissGuideItem="tissGuideItem"
                    :settingsId="settingsId"
                    :specialCases="specialCases"
                    @changeMonocular="changeMonocular"
                    @procedureToCalc="setProcedureToCalc"
                    @changeProcedure="changeProcedure"
                    @change-operational-cost-and-medical-fee="changeOperationalCostAndMedicalFee"
                  /> -->
                  <div v-if="validated && !tissGuideItem.item_id" class="custom-invalid-feedback">Campo obrigatório</div>
                </b-form-group>
              </b-col>

              <b-col cols="5">
                <b-form-group>
                  <label for="table_name" class="title-styles">
                      Tabela
                  </label>
                  <b-form-input
                    autocomplete="off"
                    :placeholder="getPlaceholder(tissGuideItem)"
                    readonly
                  />
                  <div v-if="validated && !tissGuideItem.table_name" class="custom-invalid-feedback">Campo obrigatório</div>
                </b-form-group>
              </b-col>

              <!-- <b-col cols="5" class="mt-3">
                  <label for="description" class="title-styles">
                    Descrição
                  </label>
                  <b-form-input
                    placeholder="Descrever"
                    autocomplete="off"
                    v-model="tissGuideItem.description"
                    readonly
                  />
                  <div v-if="validated && !tissGuideItem.description" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-col> -->

              <b-col cols="3">
                  <div class="d-flex justify-content-between title-styles qtde-label" for="quantity">
                    <span>Qtde.</span>
                    <span
                      v-if="guide != 'hospitalization_summary'"
                      class="checkbox"
                    >
                      <input
                        type="checkbox"
                        v-model="tissGuideItem.individual"
                      />
                      Individual ?
                    </span>
                  </div>
                  <multiselect
                    v-model="tissGuideItem.quantity"
                    placeholder="Selecionar"
                    :options="quantities"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    :internal-search="false"
                    class="with-border"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>
                    <template slot="noResult"> Nenhum resultado </template>
                  </multiselect>
                  <div v-if="validated && !tissGuideItem.quantity" class="custom-invalid-feedback">Campo obrigatório</div>
              </b-col>

              <b-col cols="2">
                <b-form-group>
                  <label for="pathway" class="title-styles">Via</label>
                  <multiselect
                    id="pathway"
                    v-model="tissGuideItem.pathway"
                    placeholder="Selecionar"
                    :options="vias"
                    label="label"
                    :option-height="40"
                    :allow-empty="true"
                    :showLabels="false"
                    :showNoResults="false"
                    :internal-search="false"
                    class="with-border"
                    :key="reloadFields"
                    @remove="tissGuideItem.pathway = null"
                    @select="changePathway"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>
                    <template slot="noResult"> Nenhum resultado </template>
                  </multiselect>
                  <b-form-invalid-feedback :state="validProp('pathway')">
                    Campo obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col cols="2">
                <b-form-group>
                  <label for="technique" class="title-styles">
                    <span>
                      Tec.
                    </span>
                  </label>
                  <multiselect
                    id="technique"
                    v-model="tissGuideItem.technique"
                    placeholder="Selecionar"
                    :options="techniques"
                    label="label"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="false"
                    :allow-empty="true"
                    :internal-search="false"
                    class="with-border"
                    :key="reloadFields"           
                    @select="changeTechnique"
                  >
                    <template slot="caret">
                      <div class="chevron">
                        <ChevronDown />
                      </div>
                    </template>
                    <template slot="noResult"> Nenhum resultado </template>
                  </multiselect>
                  <b-form-invalid-feedback :state="validProp('technique')">
                    Campo obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                  <hr id="hr" />
              </b-col>
          </b-row>

          <ProfessionalParticipationModule
            :reloadFields="reloadFields"
            :guide="guide"
            :settingsId="settingsId"
            :professionals="tissGuideItem.professionals"
            :procedureValue="tissGuideItem.total_value"
            :tissGuideItem="tissGuideItem"
            :invoicingTissSetting="invoicingTissSetting"
            :participationPercentage="participationPercentage"
            :beneficiariesList="beneficiariesList"
            @addProfessional="$emit('addProfessional', $event)"
            @updateProfessional="$emit('updateProfessional', $event)"
            @deleteProfessional="$emit('deleteProfessional', $event)"
          />

          <b-row v-if="validated && !tissGuideItem.professionals.length > 0">
            <b-col cols="12" >
              <div class="custom-invalid-feedback justify-center">Adicione pelo menos um profissional</div>
            </b-col>
          </b-row>

          <b-row>
              <b-col cols="12">
                <hr id="hrFinal" />
              </b-col>
          </b-row>

          <div class="wrapper-button">
            <b-button
              v-if="tissGuideItem.id"
              variant="primary"
              size="lg"
              @click="addOrUpdateTissGuideItem"
            >
              Alterar dados da execução
            </b-button>
            <b-button
              v-else
              variant="primary"
              size="lg"
              @click="addOrUpdateTissGuideItem"
            >
              Salvar dados da execução
            </b-button>
          </div>
      </div>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import { cloneDeep } from 'lodash'
import { getProcedureValue } from '@/utils/invoicingTissHelper'

export default {
  name: 'ProceduresFormModal',
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    // ProcedureFieldModule: () => import('./Modules/ProcedureFieldModule'),
    ProfessionalParticipationModule: () => import('./Modules/ProfessionalParticipationModule'),
    ItemHealthPlanSimpleSelector: () => import('@items/components/ItemHealthPlanSimpleSelector'),
  },
  props: {
    guide: String,
    form: Object,
    settingsId: String,
    tissGuideItem: Object,
    techniques: Array,
    invoicingTissSetting: Object,
    participationPercentage: Object,
    specialCases: Object,
    beneficiariesList: Array
  },
  data() {
    return {
      validated: false,
      quantities: Array.from({length: 10}, (_, i) => i + 1),
      isDisabled: false,
      procedure: null,
      getPriceDebounced: Function,
      clearProcedureEdit: Function,
      vias: [
        { label: 'Única (Bilateral)', value: '1' },
        { label: 'Mesma via', value: '2', $isDisabled: this.isDisabled },
        { label: 'Diferente vias', value: '3', $isDisabled: this.isDisabled }
      ],
      reloadFields: 1
    }
  },
  methods: {
    onHidden(){
      this.$emit('onHide')
    },
    setTissGuideItem(item) {

      this.tissGuideItem.item = item
      this.tissGuideItem.item_id = item.id

      this.tissGuideItem.initalProcedure = cloneDeep(item)
      this.procedureSelected = item
    
      if (Object.hasOwnProperty.bind(this.tissGuideItem)('procedure_description')) {
        this.tissGuideItem.procedure_description = item.name
      } else this.tissGuideItem.description = item.name

        this.tissGuideItem.table_name = item.health_plan_props.table_number
        this.tissGuideItem.medical_fee = item.health_plan_props.medical_fee
        this.tissGuideItem.operational_cost = item.health_plan_props.operational_cost
        this.tissGuideItem.anesthetic_port = item.health_plan_props.anesthetic_port
        this.monocular = item.health_plan_props.monocular
        this.tissGuideItem.unitary_value = getProcedureValue(item.health_plan_props, this.tissGuideItem, this.specialCases)
    },
    calculatePrice(inputValue) {
      this.tissGuideItem.total_value = inputValue * (this.tissGuideItem.quantity * this.tissGuideItem.unitary_value)
    },
    setProcedureToCalc(procedure) {
      this.procedure = procedure
    },
    // editProcedureForm(){
      // if (!this.tissGuideItem?.item) return
      // this.procedureForm = {
      //   ...this.tissGuideItem
      // }
      // this.procedureForm.pathway = this.tissGuideItem.pathway?.value ? this.tissGuideItem.pathway : this.vias.find(option => option.value === this.tissGuideItem.pathway)
      // this.procedureForm.technique = this.tissGuideItem.technique?.value ? this.tissGuideItem.technique : this.techniques.find(option => option.value === this.tissGuideItem.technique)
      // this.procedureForm.procedure = this.tissGuideItem.item
      // this.procedureForm.procedure_id = this.tissGuideItem.procedure_id || this.tissGuideItem.item_id || null
      // this.procedureForm.execution_date = this.moment(this.procedureForm.execution_date).toDate()
      // this.procedureForm.medical_fee = this.tissGuideItem.medical_fee || 0
      // this.procedureForm.anesthetic_port = this.tissGuideItem.anesthetic_port || 0
      // this.procedureForm.operational_cost = this.tissGuideItem.operational_cost || 0
    // },
    changeMonocular(value) {
      // this.isDisabled = value
    },
    changePathway(value){
      this.tissGuideItem.pathway = value
      this.reloadFields++
    },
    changeTechnique(value){
      this.tissGuideItem.technique = value
      this.reloadFields++
    },
    changeOperationalCostAndMedicalFee(values) {
      this.tissGuideItem.operational_cost = values.operational_cost
      this.tissGuideItem.medical_fee = values.medical_fee
      this.tissGuideItem.anesthetic_port = values.anesthetic_port
    },
    getDefaultForm() {
      return {
        procedure: {},
        professionals: [],
        execution_date: null,
        start_time: null,
        end_time: null,
        table_name: 22,
        procedure_id: null,
        description: null,
        quantity: null,
        individual: false,
        pathway: null,
        technique: null,
        total_value: 0,
        operational_cost: 0,
        medical_fee: 0,
        anesthetic_port: 0,
      }
    },
    validProp(prop) {
      if (!this.validated || !this.requiredFields[prop]) return null  
      return !!this.tissGuideItem[prop]
    },
    isValidForm() {
      this.validated = true

      const procedureKeys = [
        'execution_date',
        'start_time',
        'end_time',
        'pathway',
        'technique',
      ]

      if (Object.keys(this.requiredFields).filter(key => procedureKeys.includes(key)).some(key => this.requiredFields[key] && !this.tissGuideItem[key])) {
        return false
      }

      return this.tissGuideItem.table_name &&
        this.tissGuideItem.procedure_id &&
        this.tissGuideItem.description &&
        this.tissGuideItem.quantity &&
        this.tissGuideItem.medical_fee &&
        this.tissGuideItem.total_value &&
        this.tissGuideItem.professionals.length
    },
    addOrUpdateTissGuideItem() {
      this.tissGuideItem.total_value = this.tissGuideItem.professionals.reduce((acc, curr) => acc + curr.value, 0)
      if(!this.isValidForm()) return
      this.$emit('addOrUpdateTissGuideItem', this.tissGuideItem)
      this.closeModal()
    },
    closeModal() {
      this.validated = false;
      this.$emit('onHide')
      this.$bvModal.hide('realized_procedures_form_modal')
    },
    changeProcedure(procedure) {      
      this.procedure = procedure?.invoicing_tiss_setting_id ? procedure.procedure : procedure
      this.$forceUpdate()
    },
    getPlaceholder(item){
      const table_name = item?.tiss_guide_item?.table_name ?? item?.table_name
        switch(parseInt(table_name)) {
          case 22: return 'TUSS - Procedimentos e eventos em saúde (medicina, odonto e demais áreas)'
          case 0: return 'TUSS - Tabela própria das operadoras'
          default: return table_name
			}
    }
  },
  computed: {
    ...mapState('manageGuides', ['requiredFields'])
  },
  watch: {
    tissGuideItem(newValue) {
      this.tissGuideItem.execution_date = this.moment(this.tissGuideItem.execution_date).toDate()
      this.tissGuideItem.procedure = newValue.procedure || newValue.item || newValue.initalProcedure
      this.tissGuideItem.procedure_id = newValue.procedure_id || newValue.item_id || null
      this.tissGuideItem.pathway = newValue.pathway?.value ? newValue.pathway : this.vias.find(option => option.value === newValue.pathway)
      this.tissGuideItem.technique = newValue.technique?.value ? newValue.technique : this.techniques.find(option => option.value === newValue.technique)
      this.tissGuideItem.table_name = newValue?.procedure?.table_number ?? newValue?.table_number ?? 22
      this.tissGuideItem.medical_fee = newValue.medical_fee || 0
      this.tissGuideItem.anesthetic_port = newValue.anesthetic_port || 0
      this.tissGuideItem.operational_cost = newValue.operational_cost || 0
      this.tissGuideItem.professionals = newValue.professionals || []
    }
  }
}
</script>

<style lang="scss">
#realized_procedures_form_modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }

    .around-content {
      padding: 24px 24px 0 24px;
    }

    .wh-button {
      color: var(--blue-500);
    }

    .checkbox {
      color: gray;
      font-size: 15px;
      text-align: right;
    }
  }

  .wrapper-button {
    width: 100%;
    display: inline-flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: var(--neutral-000);
    }
  }

  .title-styles {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #0a184c;
    margin: 0;
  }

  .form-title{
    color: var(--neutral-600);
    font-size: 18px;
    font-weight: bolder;
    line-height: 28px;
  }
  #hrFinal {
    margin-top: 8px !important;
  }
  #hr {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
  .qtde-label {
    margin-bottom: 5px;
  }
}
</style>
